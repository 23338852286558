import React from "react";
import PropTypes from "prop-types";
import CardHeader from "./CardHeader";
import { CCard, CCardBody, CCardFooter, CCol, CRow } from "@coreui/react";

function Card({ icon, label, customMenu, children, footer, ...props }) {
  return (
    <CCard {...props}>
      <CardHeader>
        <CRow>
          <CCol xs={customMenu ? "8" : "12"} sm={customMenu ? "6" : "12"}>
            <h3 className="card-title">
              {icon && <i className={`${icon} mr-3`} />}
              {label}
            </h3>
          </CCol>
          <CCol className="text-right">
            <span>{customMenu && customMenu}</span>
          </CCol>
        </CRow>
      </CardHeader>
      <CCardBody>{children}</CCardBody>
      {footer && <CCardFooter>{footer}</CCardFooter>}
    </CCard>
  );
}

Card.propTypes = {
  customMenu: PropTypes.element,
  icon: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default Card;
