const initialState = {
  isLoading: false,
  wallet: {},
  transactions: {
    history: [],
    pagination: {},
  },
  bankAccount: {
    bank: "Banco da Amazonia",
    bankId: "003",
    userId: null,
    agency: null,
    account: null,
    account_type: "cc",
  },
  banks: [],
};

const walletReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "setTransactionsPagination":
      return {
        ...state,
        transactions: {
          ...state.transactions,
          pagination: rest.pagination,
        },
      };

    case "setBankAccountData":
      return { ...state, bankAccount: rest.bankAccountData };

    case "setWalletData":
      return { ...state, wallet: rest.walletData };

    case "setWalletIsLoading":
      return { ...state, isLoading: rest.boolean };

    case "setTransactionsData":
      return {
        ...state,
        transactions: {
          ...state.transactions,
          history: rest.transactions,
        },
      };

    case "setAvailableBanks":
      return { ...state, banks: rest.bankList };

    default:
      return state;
  }
};

export default walletReducer;
