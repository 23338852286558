import Swal              from "sweetalert2";
import { api2, headers } from "../index";

export default class discountsRequests {
  constructor(onLoading, onError) {
    this.setLoading = (boolean) => onLoading(boolean);
    this.handleError = (errorObject) => onError(errorObject);
    this.getPromotionsFromCity = this.getPromotionsFromCity.bind(this);
    this.getDiscounts = this.getDiscounts.bind(this);
    this.postDiscount = this.postDiscount.bind(this);
    this.getMyOwnDiscounts = this.getMyOwnDiscounts.bind(this);
    this.putToggleDiscountStatus = this.putToggleDiscountStatus.bind(this);
  }

  async getPromotionsFromCity(state, city) {
    try {
      this.setLoading(true);
      const url = `/discount/${state}/${city}`;
      return await api2.get(url, { ...headers() });
    } catch (error) {
      if (error.response?.data?.message === "No discounts found!")
        return
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async getDiscounts(filters) {
    try {
      this.setLoading(true);
      const url = `/discounts`;
      const response = await api2.get(url, { ...headers(), params: filters });
      return response;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async postDiscount(discount, setLoading) {
    try {
      this.setLoading(true);
      if (setLoading) setLoading(true);
      const url = "/discount/";
      const response = await api2.post(url, discount);
      Swal.fire({
        title: response.status === 201 ? "Sucesso!" : "Atenção!",
        icon: response.status === 201 ? "success" : "warning",
        text: response.data.message,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: "Beleza!",
      });
      return response;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
      if (setLoading) setLoading(false);
    }
  }

  async getMyOwnDiscounts(storeId) {
    try {
      this.setLoading(true);
      let url = `/discount/${storeId}`;
      return await api2.get(url, headers());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async putToggleDiscountStatus(discountId, userId, status) {
    try {
      this.setLoading(true);
      let url = `/discount/activity/${discountId}/${userId}/${status}`;
      return await api2.put(url, {}, headers());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async deleteDiscount(discountId) {
    const url = `/discount/delete/${discountId}`;
    return await api2.delete(url, {}, headers());
  }
}
