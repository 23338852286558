// GLOBAL
export function setIsLoading(isLoading) {
  return { type: "setIsLoading", isLoading };
}

export function setBrazilStates(stateList) {
  return { type: "brazilStates", stateList };
}

export function setSelectedState(stateObject) {
  return { type: "setBrazilSelectedState", stateObject };
}

export function setSelectedCity(cityObject) {
  return { type: "setBrazilSelectedCity", cityObject };
}

export function setResetSelectedStateAndCity() {
  return { type: "setResetSelectedStateAndCity" };
}

export function setIsLoggedIn(boolean) {
  return { type: "auth", isLoggedIn: boolean };
}

export function setImageUpload(file) {
  return { type: "setImageUpload", file };
}

export function setResetImageUpload() {
  return { type: "setResetImageUpload" };
}

// USER
export function setUserAccount(account) {
  return { type: "setUserAccount", account };
}

export function setUserNetwork(network) {
  return { type: "setUserNetwork", network };
}

export function setIsLoadingUser(boolean) {
  return { type: "setUserIsLoading", boolean };
}

export function setAccountData(userDataId, userData) {
  return { type: "setAccountData", userDataId, userData };
}

export function setHasUpdatedAccountData(hasUpdated) {
  return { type: "setHasUpdatedAccountData", hasUpdated };
}

export function setUserCurrentCity(cityName) {
  return { type: "setUserCurrentCityName", cityName };
}

// WALLET
export function setIsWalletLoading(boolean) {
  return { type: "setIsWalletLoading", boolean };
}

export function setWalletData(walletData) {
  return { type: "setWalletData", walletData };
}

export function setTransactionsData(transactions) {
  return { type: "setTransactionsData", transactions };
}

export function setTransactionsPagination(pagination) {
  return { type: "setTransactionsPagination", pagination };
}

export function setBankAccountData(bankAccountData) {
  return { type: "setBankAccountData", bankAccountData };
}

export function setAvailableBanks(bankList) {
  return { type: "setAvailableBanks", bankList };
}

// DISCOUNTS
export function setIsLoadingDiscounts(isLoading) {
  return { type: "setIsLoadingDiscounts", isLoading };
}

export function setAvailableDiscounts(discountList) {
  return { type: "setAvailableDiscounts", discountList };
}

export function setAvailableDiscountsPagination(pagination) {
  return { type: "setAvailableDiscountsPagination", pagination };
}

export function setDiscountSearchString(searchString) {
  return { type: "setDiscountSearchString", searchString };
}

export function setDiscountSearchType(searchType) {
  return { type: "setDiscountSearchType", searchType };
}

export function setNewDiscountData(data, value) {
  return { type: "setNewDiscountData", data, value };
}

export function setMyOwnDiscounts(discountList) {
  return { type: "setMyOwnDiscounts", discountList };
}

export function setConcumerReservatedDiscounts(reservatedDiscountList) {
  return { type: "setConcumerReservatedDiscounts", reservatedDiscountList };
}

export function resetDiscountFilters() {
  return { type: "setResetDiscountFilters" };
}

// STORES
export function setSelectedStores(selectedList) {
  return { type: "setSelectedStores", selectedList };
}

export function setAvailableStores(storeList) {
  return { type: "setAvailableStores", storeList };
}

export function setStoresCategories(categoryList) {
  return { type: "setStoresCategories", categoryList };
}

export function setSelectedCategories(category) {
  return { type: "setSelectedCategories", category };
}

export function setNewStoreFormData(key, value) {
  return { type: "setNewStoreFormData", key, value };
}

export function setSubscriptionData(subscription) {
  return { type: "setSubscriptionData", subscription };
}

export function setSubscriptionLoading(isLoading) {
  return { type: "setSubscriptionLoading", isLoading };
}

export function setResetSelectedCategories() {
  return { type: "setResetSelectedCategories" };
}

export function setCurrentCityStores(storeList) {
  return { type: "setCurrentCityStores", storeList };
}
