import localStorageKeys from "../../localstorage";

const initialState = {
  account: {},
  isLoading: false,
  userId: null,
  hasUpdatedAccountData: false,
  cityName: null,
  network: null
};

const userReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "setUserAccount":
      return { ...state, account: rest.account };

    case "setUserNetwork":
      return { ...state, network: rest.network };

    case "setUserIsLoading":
      return { ...state, isLoading: rest.boolean };

    case "setAccountData":
      return {
        ...state,
        hasUpdatedAccountData: true,
        account: {
          ...state.account,
          [rest.userDataId]: rest.userData,
        },
      };

    case "resetAccountData":
      return {
        ...state,
        account: {
          ...rest.resetPayload,
        },
      };

    case "setHasUpdatedAccountData":
      return { ...state, hasUpdatedAccountData: rest.hasUpdated };

    case "setUserCurrentCityName":
      return { ...state, cityName: rest.cityName };

    default:
      const userId = JSON.parse(localStorage.getItem(localStorageKeys.Token))?.id;

      return { ...state, userId };
  }
};

export default userReducer;
