import { combineReducers } from "redux";
import discountReducer     from "./discounts";
import rootReducer         from "./root";
import userReducer         from "./user";
import walletReducer       from "./wallet";
import storesReducer       from "./stores";
import subscriptionReducer from "./subscription";

const reducers = combineReducers({
  coreUI: rootReducer,
  user: userReducer,
  wallet: walletReducer,
  discount: discountReducer,
  stores: storesReducer,
  subscription: subscriptionReducer,
});

export default reducers;
