import localStorageKeys from "../../localstorage";
import api, { headers } from "../index";

export default class transactionsRequests {
  constructor(onLoading, onError) {
    this.setLoading = boolean => onLoading(boolean);
    this.handleError = error => onError(error);
    this.getTransactions = this.getTransactions.bind(this);
    this.getUserIdFromLocalStorage = this.getUserIdFromLocalStorage.bind(this);
  }

  getUserIdFromLocalStorage() {
    return JSON.parse(localStorage.getItem(localStorageKeys.Token)).id;
  }

  async getTransactions() {
    try {
      this.setLoading(true);
      const url = `/transactions/${this.getUserIdFromLocalStorage()}`;
      const response = await api.get(url, headers());
      return response.data;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }
}
