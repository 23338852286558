const initialState = {
  isLoading: false,
  searchString: "",
  searchType: "storeName",
  available: [],
  newDiscount: {
    name: "",
    store: "",
    discountPercentage: "",
    useLimit: "",
    discountStartDate: "",
    discountEndDate: "",
    description: "",
    thumbnail: "",
  },
  myOwnDiscounts: [],
  reservatedDiscounts: [],
  pagination: {}
};

const discountReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "setAvailableDiscountsPagination":
      return { ...state, pagination: rest.pagination };

    case "setConcumerReservatedDiscounts":
      return { ...state, reservatedDiscounts: rest.reservatedDiscountList };

    case "setIsLoadingDiscounts":
      return { ...state, isLoading: rest.isLoading };

    case "setMyOwnDiscounts":
      return { ...state, myOwnDiscounts: rest.discountList };

    case "setAvailableDiscounts":
      return { ...state, available: rest.discountList };

    case "setDiscountSearchString":
      return { ...state, searchString: rest.searchString };

    case "setDiscountSearchType":
      return { ...state, searchType: rest.searchType };

    case "setNewDiscountData":
      return {
        ...state,
        newDiscount: { ...state.newDiscount, [rest.data]: rest.value },
      };

    case "setResetDiscountFilters":
      return {
        ...state,
        searchString: "",
        searchType: "storeName",
      };

    default:
      return state;
  }
};

export default discountReducer;
