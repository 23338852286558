const appName = "POUP+";

const localStorageKeys = {
  Token: `@${appName}#Token`,
  User: `@${appName}#User`,
  Login: `@${appName}#Login`,
  DarkMode: `@${appName}#DarkMode`,
  Invite: `@${appName}#Invite`,
  TipsSeen: `@${appName}#TipsSeen`,
  StatesAndCities: `@${appName}#StatesAndCitiesFromBrazil`,
  LastCityName: `@${appName}#LastCityName`,
  StoreCategories: `@${appName}#StoreCategories`,
};

export default localStorageKeys;
