import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cibYoutube,
  cilArrowLeft,
  cilCheckCircle,
  cilAccountLogout,
  cilBullhorn,
  cilMinus,
  cilPlus,
  cilSearch,
  cilWallet,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilUserPlus,
  cilUserX,
  cilCart,
  cilPen,
  cilPencil,
  cilPenAlt,
  cilPenNib,
  cilPhone,
  cilPaperPlane,
  cilPaint,
  cilPaperclip,
  cilParagraph,
  cilActionRedo,
  cilActionUndo,
  cilAddressBook,
  cilDataTransferUp,
  cilDataTransferDown,
  cilArrowCircleBottom,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilArrowCircleTop,
  cilAsterisk,
  cilAsteriskCircle,
  cilBellExclamation
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    // after importing the icon
    // afeter importing the icon
    // you wanna use from up there ⤴,
    // export it here ⤵
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cibYoutube,
    cilArrowLeft,
    cilCheckCircle,
    cilAccountLogout,
    cilBullhorn,
    cilMinus,
    cilPlus,
    cilSearch,
    cilWallet,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilUserPlus,
    cilUserX,
    cilCart,
    cilPen,
    cilPencil,
    cilPenAlt,
    cilPenNib,
    cilPhone,
    cilPaperPlane,
    cilPaint,
    cilPaperclip,
    cilParagraph,
    cilActionRedo,
    cilActionUndo,
    cilAddressBook,
    cilDataTransferUp,
    cilDataTransferDown,
    cilArrowCircleBottom,
    cilArrowCircleLeft,
    cilArrowCircleRight,
    cilArrowCircleTop,
    cilAsterisk,
    cilAsteriskCircle,
    cilBellExclamation,
  }
);
