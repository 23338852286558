import { applyMiddleware, createStore, compose } from "redux";
import thunk                                     from "redux-thunk";
import Reducers                                  from "./reducers";

const middleware = applyMiddleware(thunk);
const reduxDevToolsOptions = { trace: true, traceLimit: 25 };

const composer =
  process.env.NODE_ENV === "development"
    ? createStore(
      Reducers,
      compose(
        middleware,
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__(reduxDevToolsOptions)
          : (f) => f
      )
    )
    : createStore(Reducers, middleware);

const store = composer;

export default store;
