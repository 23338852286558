import { lazy, useEffect, Suspense }    from "react";
import axios                            from "axios";
import { useDispatch, useSelector }     from "react-redux";
import localStorageKeys                 from "./localstorage";
import { setIsLoggedIn }                from "./redux/actions";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import RecoverPassword                  from "./views/public/RecoverPassword";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-picky/dist/picky.css";
import "./scss/style.scss";
import "./App.css";

const TheLayout      = lazy(() => import("./containers/TheLayout"));
const Login          = lazy(() => import("./views/public/Login"));
const CreateAccount  = lazy(() => import("./views/public/CreateAccount"));
const ConfirmAccount = lazy(() => import("./views/public/ConfirmAccount"));
const NotFound       = lazy(() => import("./views/public/NotFound"));
const Logout         = lazy(() => import("./views/common/Logout"));

let tokenExpireTimeout;

const App = () => {
  const dispatch       = useDispatch();
  const isLoggedIn     = useSelector(state => state.coreUI.isLoggedIn);
  const _setIsLoggedIn = boolean => dispatch(setIsLoggedIn(boolean));

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem(localStorageKeys.StatesAndCities))?.length) {
      axios.get(process.env.REACT_APP_BRASIL).then((response) => {
        localStorage.setItem(
          localStorageKeys.StatesAndCities, 
          JSON.stringify([...response.data.estados])
        );
        dispatch({
          type: "brazilStates", 
          stateList: response.data.estados
        });
      });
    }
  }, [dispatch]);

  useEffect(() => {
    const tokenIsExpired = expiresIn => new Date(expiresIn) < new Date();

    const scheduledLogoutOnTokenExpiration = expiresIn => {
      const startDate    = new Date();
      const endDate      = new Date(expiresIn);
      const miliseconds  = endDate - startDate;
      tokenExpireTimeout = setTimeout(logout, miliseconds);
    };

    const matchPublicRoutes = () => {
      const matchRoute = route => window.location.href.match(route);
      const routes     = ["/cadastro", "/confirmar", "/recuperar"];
      return routes.reduce((redirect, route) => !!matchRoute(route), false);
    }

    const login = tokenExpiresIn => {
      if (isLoggedIn === false) {
        _setIsLoggedIn(true);
        scheduledLogoutOnTokenExpiration(tokenExpiresIn);
        if (matchPublicRoutes()) {
          window.location.assign("/");
        }
      }
    };

    const logout = () => {
      if (isLoggedIn === true) {
        _setIsLoggedIn(false);
        if (!window.location?.href.match("entrar")) {
          window.location.assign("/");
        }
      }
    };

    const tokenVerificationRoutine = () => {
      const token = JSON.parse(localStorage.getItem(localStorageKeys.Token));
      if (token && token.jwt && token.expiresIn && token.id) {
        if (tokenIsExpired(token.expiresIn.split("GMT")[0])) logout();
        else login();
      } else {
        logout();
      }
    };

    tokenVerificationRoutine();

    return () => {
      clearTimeout(tokenExpireTimeout);
    };
    // eslint-disable-next-line
  }, []);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"/>
    </div>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
          {isLoggedIn ? (
            <Switch>
              <Route path="/" render={props => <TheLayout {...props} />}/>
            </Switch>
          ) : (
            <Switch>
              <Route
                exact
                path="/cadastro/:inviterUsername"
                name="Criar Conta"
                render={props => <CreateAccount {...props} />}
              />
              <Route
                exact
                path="/recuperar-senha/:token/:email"
                name="Recuperar Senha"
                render={props => <RecoverPassword {...props} />}
              />
              <Route
                exact
                path="/confirmar-conta/:credentials"
                name="Confirmar conta"
                render={props => <ConfirmAccount {...props} />}
              />
              <Route
                exact
                path="/sair"
                name="Sair"
                render={props => <Logout {...props} />}
              />
              <Route
                exact
                path="/"
                name="Entrar"
                render={props => <Login {...props} />}
              />
              <Route
                exact
                path="*"
                name="404"
                render={props => <NotFound {...props} />}
              />
            </Switch>
          )}
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
