import Swal from "sweetalert2";


function alertError(message) {
  Swal.fire({
    title: "Erro",
    icon: "error",
    text: message,
    showCloseButton: true,
  })
}

function validateError(error) {
  if (error.response?.data && typeof (error.response.data) !== "string") {
    alertError(error.response.data.message || "Erro!");
    const apiMessageMatchString = string => error.response.data.message.match(string);
    if (apiMessageMatchString("Unauthorized")) {
      window.location.assign("/sair");
    }
    return error;
  } else {
    console.error(error);
    alertError(error.message);
    return error;
  }
}

export default validateError;
