import { toast } from "react-toastify";
import api, { api2, headers } from "../index";

export default class userRequests {
  constructor(onLoading, onError) {
    this.setLoading  = boolean => onLoading(boolean);
    this.handleError = error => onError(error);
    this.postUsers   = this.postUsers.bind(this);
    this.getUser     = this.getUser.bind(this);
    this.putUser     = this.putUser.bind(this);
    this.getMyNodes  = this.getMyNodes.bind(this);
  }

  async postUsers(payload) {
    try {
      this.setLoading(true);
      return await api2.post("/user/create", payload);
    } catch (error) {
      toast.error(error.response.data.errors[0], {
        autoClose: false,
        position: "top-right"
      });
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async getUser({ userId }) {
    try {
      this.setLoading(true);
      const url = `/user/${userId}`;
      const response = await api.get(url, headers());
      return response.data;
    } catch (error) {
      return this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async putUser({ userId, data }) {
    try {
      this.setLoading(true);
      const url = `/user/${userId}`;
      const response = await api.put(url, data, headers());
      return response.data;
    } catch (error) {
      return this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async getMyNodes(userId) {
    try {
      this.setLoading(true);
      const response = await api.get(`/user/myNodes/${userId}`, headers());
      return response.data;
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }
}
