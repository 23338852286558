import api, { headers } from "../index";

export default class walletRequests {
  constructor(onLoading, onError) {
    this.setLoading         = boolean => onLoading(boolean);
    this.handleError        = error => onError(error);
    this.getWallet          = this.getWallet.bind(this);
    this.postWalletWithdraw = this.postWalletWithdraw.bind(this);
    this.getBanks           = this.getBanks.bind(this);
    this.postBankAccount    = this.postBankAccount.bind(this);
    this.putBankAccount    = this.putBankAccount.bind(this);
  }

  async getWallet(userId, page = 0) {
    try {
      this.setLoading(true);
      const url      = `/wallet?userId=${userId}&page=${page}`;
      const response = await api.get(url, headers());
      return response.data;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async postWalletWithdraw(amountRequired, userId) {
    try {
      this.setLoading(true);
      const response = await api.post("/wallet/withdraw", {
        amountRequired, userId,
      }, headers());
      return response;
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async getBanks() {
    try {
      this.setLoading(true);
      const response = await api.get("/banks", headers());
      return response;
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async postBankAccount(payload) {
    try {
      this.setLoading(true);
      const response = await api.post("/wallet/bankAccount", payload, headers());
      return response;
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async putBankAccount(payload) {
    try {
      this.setLoading(true);
      const response = await api.put("/wallet/bankAccount", payload, headers());
      return response;
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }

}
