import api, { headers } from "../index";

export default class subscriptionRequests {
  constructor(onLoading, onError) {
    this.setLoading = boolean => onLoading(boolean);
    this.handleError = error => onError(error);
    this.getSubscription = this.getSubscription.bind(this);
    this.postSubscription = this.postSubscription.bind(this);
  }

  async getSubscription(userId) {
    try {
      this.setLoading(true);
      const url = `/subscription/${userId}`;
      const response = await api.get(url, headers());
      return response.data;
    } catch (error) {
      return this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async postSubscription(userId) {
    try {
      this.setLoading(true);
      const url = `/subscription/`;
      const payload = { userId };
      const response = await api.post(url, payload, headers());
      return response.data;
    } catch (error) {
      return this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }
}
