import localStorageKeys from "../../localstorage";

const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  isLoading: false,
  darkMode: null,
  userId: null,
  isLoggedIn: false,
  brazil: {
    states: [],
    selectedState: [],
    selectedCity: [],
  },
  imageToUpload: null
};

const rootReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };

    case "darkMode":
      localStorage.setItem(localStorageKeys.DarkMode, rest.darkMode);
      return { ...state, ...rest };

    case "setIsLoading":
      return { ...state, isLoading: rest.isLoading };

    case "userId":
      return { ...state, userId: rest.userId };

    case "auth":
      return { ...state, isLoggedIn: rest.isLoggedIn };

    case "brazilStates":
      return { ...state, brazil: { ...state.brazil, states: rest.stateList } };

    case "setBrazilSelectedState":
      return { ...state, brazil: { ...state.brazil, selectedState: rest.stateObject } };

    case "setBrazilSelectedCity":
      return { ...state, brazil: { ...state.brazil, selectedCity: rest.cityObject } };

    case "setResetSelectedStateAndCity":
      return { ...state, brazil: { ...state.brazil, selectedState: null, selectedCity: null } };

    case "setImageUpload":
      return { ...state, imageToUpload: rest.file }

    case "setResetImageUpload":
      return { ...state, imageToUpload: null }

    default:
      const darkMode = JSON.parse(localStorage.getItem(localStorageKeys.DarkMode));

      return { ...state, darkMode };
  }
};

export default rootReducer;
