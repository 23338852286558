import validateError        from "../validateError";
import authRequests         from "./auth";
import discountsRequests    from "./discounts";
import storeRequests        from "./store";
import subscriptionRequests from "./subscription";
import transactionsRequests from "./transactions";
import userRequests         from "./user";
import walletRequests       from "./wallet";
import botsRequests         from "./bots";

export default function requestsHandler(onLoading) {
  return {
    auth:         new authRequests(onLoading, validateError),
    user:         new userRequests(onLoading, validateError),
    store:        new storeRequests(onLoading, validateError),
    wallet:       new walletRequests(onLoading, validateError),
    discounts:    new discountsRequests(onLoading, validateError),
    transactions: new transactionsRequests(onLoading, validateError),
    subscription: new subscriptionRequests(onLoading, validateError),
    bots:         new botsRequests(onLoading, validateError),
  };
}
