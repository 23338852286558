import api, { headers } from "../index";

export default class botsRequests {
  constructor(onLoading, onError) {
    this.setLoading                    = boolean => onLoading(boolean);
    this.handleError                   = error => onError(error);
    this.getWhatsAppQRC                = this.getWhatsAppQRC.bind(this);
    this.postWhatsAppBotTestConnection = this.postWhatsAppBotTestConnection.bind(this);
  }

  async getWhatsAppQRC() {
    try {
      this.setLoading(true);
      const response = await api.get("/whatsapp/qr-code", headers());
      return response;
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async postWhatsAppBotTestConnection(phoneNumber) {
    try {
      this.setLoading(true);
      const response = await api.post("/whatsapp/test-conection", phoneNumber, headers());
      return response;
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }

}
