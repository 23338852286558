import { createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import requestsHandler              from "../api/requests";
import { 
  setIsLoading, setIsLoadingUser, 
  setUserAccount, setUserNetwork 
} from "../redux/actions";

const apiContext             = createContext(null);
const { Provider, Consumer } = apiContext;

function ApiContext({ children }) {
  const dispatch      = useDispatch();
  const setLoading    = boolean => {
    dispatch(setIsLoading(boolean));
    dispatch(setIsLoadingUser(boolean));
  };
  const requestSwitch = requestsHandler(setLoading);
  const userId        = useSelector(state => state.user.userId);
  const isLoggedIn    = useSelector(state => state.coreUI.isLoggedIn);

  // GLOBAL REQUESTS TRIGGER
  useEffect(() => {
    const userDataDefaultRequest = () => {
      setLoading(true);
      requestSwitch.user.getUser({ userId }).then(response => {
        if (response.data) {
          dispatch(setUserAccount(response.data.user));
          dispatch(setUserNetwork(response.data.myNodes));
        }
        setLoading(false);
      });
    };
    if (isLoggedIn) userDataDefaultRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Provider
      value={{
        postUsers: requestSwitch.user.postUsers,
        postLogin: requestSwitch.auth.postLogin,
        putPasswordRecoveryToken: requestSwitch.auth.putPasswordRecoveryToken,
        putNewPassword: requestSwitch.auth.putNewPassword,
        getUser: requestSwitch.user.getUser,
        putUser: requestSwitch.user.putUser,
        getMyNodes: requestSwitch.user.getMyNodes,
        getWallet: requestSwitch.wallet.getWallet,
        postWalletWithdraw: requestSwitch.wallet.postWalletWithdraw,
        getBanks: requestSwitch.wallet.getBanks,
        postBankAccount: requestSwitch.wallet.postBankAccount,
        putBankAccount: requestSwitch.wallet.putBankAccount,
        postConfirmAccount: requestSwitch.auth.postConfirmAccount,
        resendAccountConfirmationToken: requestSwitch.auth.putResendToken,
        getStoreCategories: requestSwitch.store.getStoreCategories,
        postStore: requestSwitch.store.postStore,
        getStores: requestSwitch.store.getStores,
        getStoresFromUser: requestSwitch.store.getStoresFromUser,
        getStoreByCityName: requestSwitch.store.getStoreByCityName,
        putStore: requestSwitch.store.putStore,
        deleteStore: requestSwitch.store.deleteStore,
        getPromotionsFromCity: requestSwitch.discounts.getPromotionsFromCity,
        getDiscounts: requestSwitch.discounts.getDiscounts,
        postDiscount: requestSwitch.discounts.postDiscount,
        deleteDiscount: requestSwitch.discounts.deleteDiscount,
        getMyOwnDiscounts: requestSwitch.discounts.getMyOwnDiscounts,
        putToggleDiscountStatus: requestSwitch.discounts.putToggleDiscountStatus,
        getSubscription: requestSwitch.subscription.getSubscription,
        postSubscription: requestSwitch.subscription.postSubscription,
        getWhatsAppQRC: requestSwitch.bots.getWhatsAppQRC,
        postWhatsAppBotTestConnection: requestSwitch.bots.postWhatsAppBotTestConnection,
      }}
    >
      {children}
    </Provider>
  );
}

export { apiContext, Provider, Consumer };

export default ApiContext;
