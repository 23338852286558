import axios            from "axios";
import localStorageKeys from "../localstorage";

const getToken = () => JSON.parse(localStorage.getItem(localStorageKeys.Token))?.jwt || false;
const headers = () => ({ headers: { Authorization: `Bearer ${getToken()}` } });
const baseURL = process.env.REACT_APP_API_URL;
const baseURL2 = process.env.REACT_APP_API2_URL;
const api = axios.create({ baseURL });
const api2 = axios.create({ baseURL: baseURL2 });
api.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
api2.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;

export { api, api2, baseURL, baseURL2, headers, getToken };
export default api;
