const initialState = {
  isLoading: false,
  data: []
};

const subscriptionReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "setSubscriptionLoading":
      return {
        ...state,
        isLoading: rest.isLoading
      }

    case "setSubscriptionData":
      return {
        ...state,
        data: rest.subscription
      };

    default:
      return state;
  }
};

export default subscriptionReducer;
