import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CustomCardHeader = styled.div`
  background: rgb(22, 164, 82);
  background: linear-gradient(90deg,
  rgb(27, 192, 68) 0%,
  rgb(29, 161, 62) 35%,
  rgb(25, 98, 43) 100%);
  color: white;
`;

function CardHeader({ children }) {
  return (
    <CustomCardHeader className="card-header">
      {children}
    </CustomCardHeader>
  );
}

CardHeader.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default CardHeader;
