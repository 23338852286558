import Swal     from "sweetalert2";
import axios    from "axios";
import { api2 } from "..";

export default class authRequests {
  constructor(onLoading, onError) {
    this.setLoading               = boolean => onLoading(boolean);
    this.handleError              = error => onError(error);
    this.postLogin                = this.postLogin.bind(this);
    this.postConfirmAccount       = this.postConfirmAccount.bind(this);
    this.putResendToken           = this.putResendToken.bind(this);
    this.putPasswordRecoveryToken = this.putPasswordRecoveryToken.bind(this);
    this.putNewPassword           = this.putNewPassword.bind(this);
    this.api                      = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  }

  async postLogin({ email, password }) {
    try {
      this.setLoading(true);
      return await this.api.post("/login", {
        email,
        password,
      });
    } catch (error) {
      if (error.response.data.message.toLowerCase().match("conta não ativada")) {
        window.location.assign(`/confirmar-conta/${email}`);
      } else {
        this.handleError(error);
      }
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async postConfirmAccount({ email, token }) {
    try {
      this.setLoading(true);
      const response = await api2.put(`/user/confirm-account/${email}/${token}`);
      if (response.status === 200) {
        Swal.fire({
          title: "Sucesso!",
          text: response.message,
          icon: "success",
          showCloseButton: true,
          showConfirmButton: true,
        }).then(() => {
          window.location.assign("/");
        });
      }
      return response;
    } catch (error) {
      // this.handleError(error);
      Swal.fire({
        title: "Erro!",
        text: error.response?.message || error.message,
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Fechar",
      }).then(() => {
        window.location.assign("/");
      });
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async putResendToken({ email }) {
    try {
      this.setLoading(true);
      const payload  = {
        userEmail: email,
      };
      const response = await this.api.put("/user/resendtoken", payload);
      Swal.fire({
        title: "Atenção",
        text: response.data.message,
        icon: response.data.status === 200 ? "success" : "error",
        showCloseButton: true,
        showConfirmButton: true,
      });
      return response;
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async putPasswordRecoveryToken({ email }) {
    try {
      this.setLoading(true);
      return await api2.put(`/user/password-recovery/${email}`);
    } catch (error) {
      this.handleError({ ...error, email });
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async putNewPassword({ email, password, token }) {
    try {
      this.setLoading(true);
      return await api2.put(`/user/password-recovery/${email}?token=${token}&password=${password}`);
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }
}
